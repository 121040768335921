var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();_vm.action === 'create' ? _vm.createItem() : _vm.editItem()}}},[_c('v-container',[_c('Title',{staticClass:"mb-8",attrs:{"title":_vm.action === 'create' ? 'Crear plantilla' : 'Actualizar plantilla'}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"type":"text","label":"Descripción *","outlined":""},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('VueEditor',{attrs:{"placeholder":"Contenido...","editorToolbar":[
              ['bold', 'italic', 'underline', 'link'],
              [{ list: 'ordered' }, { list: 'bullet' }],
              [
                { align: '' },
                { align: 'center' },
                { align: 'right' },
                { align: 'justify' },
              ],
              [{ color: [] }, { background: [] }],
            ]},model:{value:(_vm.content),callback:function ($$v) {_vm.content=$$v},expression:"content"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-btn',{staticClass:"pa-5",attrs:{"small":"","color":"primary","type":"submit","depressed":"","disabled":!_vm.validation,"loading":_vm.loading}},[_vm._v(" Guardar ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }