<template>
  <div>
    <v-dialog
      v-model="showForm"
      width="1200"
      persistent
      @keydown.esc="showForm = false"
    >
      <v-card class="pa-sm-10 pa-5">
        <div class="text-right">
          <v-icon @click="showForm = false" style="cursor: pointer">
            mdi-close
          </v-icon>
        </div>
        <HistoryForm
          :action="action"
          :formValues="formHistory"
          :modulesToSelected="modules"
          @formHistory-created="handleCreate"
          @formHistory-updated="handleUpdate"
        />
      </v-card>
    </v-dialog>

    <WarningDialog
      ref="WarningDialogRef"
      :loading="deleting"
      @action="handleDelete"
    />

    <v-row class="mt-5">
      <v-col>
        <HistoryFormList
          @setToEdit="handleSetToEdit"
          @showWarning="showWarning"
          :loading="loading"
          :searchInput="searchInput"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import HistoryForm from "@/components/HistoryForm/HistoryForm";
import HistoryFormList from "@/components/HistoryForm/HistoryFormList";
import WarningDialog from "../../components/WarningDialog.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import axios from "axios";

export default {
  name: "FormSection",
  components: {
    HistoryForm,
    HistoryFormList,
    WarningDialog,
  },
  props: ["searchInput"],
  data() {
    return {
      loading: false,
      showForm: false,
      action: "create",
      formHistory: "",
      deleting: false,
      modules: [],
    };
  },
  computed: {
    ...mapGetters(["subForms", "formModules"]),
  },
  methods: {
    ...mapActions(["fetchForms"]),
    ...mapMutations([
      "pushForm",
      "updateForm",
      "removeForm",
      "setAlert",
      "pushDoctorForm",
      "updateDoctorForm",
      "removeDoctorForm",
    ]),
    handleCreate(formHistory) {
      this.pushForm(formHistory);
      this.pushDoctorForm(formHistory);
      this.showForm = false;
    },
    handleUpdate(formHistory) {
      this.updateForm(formHistory);
      this.updateDoctorForm(formHistory);
      this.showForm = false;
    },
    handleSetToEdit(formHistory) {
      this.action = "update";
      this.formHistory = formHistory;
      this.showForm = true;
    },
    setToCreate() {
      this.formHistory = { description: "", formDetails: [] };
      this.action = "create";
      this.showForm = true;
    },
    showWarning(formHistory) {
      this.formHistory = formHistory;
      this.$refs.WarningDialogRef.description = `¿Seguro que desea eliminar "${this.formHistory?.description}"?`;
      this.$refs.WarningDialogRef.show = true;
    },
    async handleDelete() {
      try {
        const res = await axios.delete(`/api/form/${this.formHistory.id}`);
        this.$refs.WarningDialogRef.show = false;
        this.removeForm(this.formHistory);
        this.removeDoctorForm(this.formHistory);
        const { message } = res.data;
        this.setAlert({
          show: true,
          color: "success",
          icon: "mdi-check-circle",
          timeout: 4000,
          text: message,
        });
      } catch (error) {
        console.log(error);
        this.setAlert({
          show: true,
          color: "error",
          icon: "mdi-alert-remove",
          text: error.response.data.message,
        });
      }
    },
    setModules() {
      const defaultModules = this.subForms?.map((subForm) => {
        return {
          ...subForm,
          added: false,
        };
      });

      const modulesCreated =
        this.formModules?.map((subForm) => {
          return {
            ...subForm,
            code: subForm.id.toString(),
            added: false,
          };
        }) || [];

      this.modules = defaultModules.concat(modulesCreated);
    },
  },
  watch: {
    formModules: function () {
      this.setModules();
    },
  },
  async created() {
    this.loading = true;
    await this.fetchForms();
    this.loading = false;
  },
};
</script>
