<template>
  <div>
    <v-row>
      <v-col cols="12" v-if="!formModulesFiltered.length">
        <v-card flat class="pa-8 text-center d-flex justify-center">
          <v-progress-circular
            v-if="loading"
            indeterminate
            color="primary"
          ></v-progress-circular>
          <div v-else class="text-center grey--text">
            <v-icon color="grey" large>mdi-information</v-icon>
            <div class="mt-5">
              {{ noDataText }}
            </div>
          </div>
        </v-card>
      </v-col>

      <v-col
        cols="12"
        v-for="(item, index) in formModulesFiltered"
        :key="index"
      >
        <v-card
          :hover="hover"
          :elevation="hover ? '' : '10'"
          @click="handleClick(item)"
          class="pa-5"
          height="100%"
          :style="`position: relative; cursor: ${hover ? 'pointer' : 'auto'}`"
        >
          <p class="subtitle-1 font-weight-medium">
            <span>{{ item.description }}</span>
          </p>
          <div class="body-1 my-5" v-html="item.content"></div>
          <div
            v-if="!hideActions"
            style="position: absolute; bottom: 15px; right: 15px"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  small
                  icon
                  class="mx-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="setToEdit(item)"
                >
                  <v-icon small>mdi-square-edit-outline</v-icon>
                </v-btn>
              </template>
              <span>Editar</span>
            </v-tooltip>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "FormModuleList",
  props: ["loading", "searchInput", "formModules", "hideActions", "hover"],
  computed: {
    formModulesFiltered() {
      return this.formModules?.filter((e) => {
        // return all formModules when searchInput is empty
        if (this.searchInput === "") return e;
        else {
          return (
            e?.description
              ?.toLowerCase()
              ?.includes(this.searchInput.toLowerCase()) ||
            e?.content?.toLowerCase()?.includes(this.searchInput.toLowerCase())
          );
        }
      });
    },
    noDataText() {
      return this.searchInput !== ""
        ? "Módulo de formulario no encontrado..."
        : "No hay módulos registrados...";
    },
  },
  methods: {
    setToEdit(item) {
      this.$emit("setToEdit", item);
    },
    showWarning(item) {
      this.$emit("showWarning", item);
    },
    handleClick(item) {
      this.$emit("item-click", item);
    },
  },
  created() {
    this.$emit("created");
  },
};
</script>
