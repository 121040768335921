<template>
  <div>
    <v-form
      ref="form"
      @submit.prevent="action === 'create' ? createItem() : editItem()"
    >
      <v-container>
        <Title
          :title="
            action === 'create' ? 'Crear plantilla' : 'Actualizar plantilla'
          "
          class="mb-8"
        />
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="description"
              type="text"
              label="Descripción *"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <VueEditor
              placeholder="Contenido..."
              v-model="content"
              :editorToolbar="[
                ['bold', 'italic', 'underline', 'link'],
                [{ list: 'ordered' }, { list: 'bullet' }],
                [
                  { align: '' },
                  { align: 'center' },
                  { align: 'right' },
                  { align: 'justify' },
                ],
                [{ color: [] }, { background: [] }],
              ]"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              small
              color="primary"
              class="pa-5"
              type="submit"
              depressed
              :disabled="!validation"
              :loading="loading"
            >
              Guardar
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>

<script>
import axios from "axios";
import { mapMutations, mapGetters } from "vuex";
import { VueEditor } from "vue2-editor";
import Title from "../Title.vue";

export default {
  name: "TemplateForm",
  props: ["action", "item"],
  components: {
    VueEditor,
    Title,
  },
  data() {
    return {
      id: "",
      description: "",
      content: "",
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["currentUser"]),
    validation() {
      return this.description !== "";
    },
  },
  methods: {
    ...mapMutations(["setAlert", "pushDoctorTemplates"]),
    async createItem() {
      try {
        this.loading = true;
        const templateToCreate = {
          description: this.description,
          content: this.content,
          doctorId: this.currentUser.doctor.id,
        };
        const res = await axios.post("/api/template", templateToCreate);
        const { template, message } = res.data;
        this.setAlert({
          show: true,
          color: "success",
          icon: "mdi-check-circle",
          timeout: 4000,
          text: message,
        });
        this.pushDoctorTemplates(template);
        this.$emit("item-created", template);
      } catch (error) {
        console.log(error);
        this.setAlert({
          show: true,
          color: "error",
          icon: "mdi-alert-remove",
          text: error.response.data.message,
        });
      } finally {
        this.loading = false;
      }
    },
    async editItem() {
      try {
        this.loading = true;
        const templateToUpdate = {
          id: this.id,
          description: this.description,
          content: this.content,
          doctorId: this.currentUser.doctor.id,
        };
        const res = await axios.put(
          `/api/template/${this.id}`,
          templateToUpdate
        );
        const { template, message } = res.data;
        this.setAlert({
          show: true,
          color: "success",
          icon: "mdi-check-circle",
          timeout: 4000,
          text: message,
        });
        this.$emit("item-updated", template);
      } catch (error) {
        console.log(error);
        this.setAlert({
          show: true,
          color: "error",
          icon: "mdi-alert-remove",
          text: error.response.data.message,
        });
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    item: {
      immediate: true,
      handler() {
        this.id = this.item.id;
        this.description = this.item.description;
        this.content = this.item.content;
      },
    },
  },
};
</script>
