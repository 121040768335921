<template>
  <v-container class="pa-md-5" fluid>
    <v-row>
      <v-col cols="12">
        <h2 class="secondary--text">Formularios</h2>
        <v-divider class="mt-2"></v-divider>
        <div class="mb-8 d-flex">
          <v-tabs
            v-model="display"
            icons-and-text
            height="40"
            color="secondary"
            slider-color="secondary"
          >
            <v-tab>
              <div class="caption font-weight-medium d-flex align-center">
                <div>Historial</div>
              </div>
            </v-tab>
            <v-tab>
              <div class="caption font-weight-medium d-flex align-center">
                <div>Módulos</div>
              </div>
            </v-tab>
            <v-tab>
              <div class="caption font-weight-medium d-flex align-center">
                <div>Plantillas</div>
              </div>
            </v-tab>
          </v-tabs>
        </div>

        <div class="d-md-flex align-center">
          <div class="mr-5">
            <v-text-field
              v-model="searchInput"
              label="Buscar"
              append-icon="mdi-magnify"
              type="text"
              clearable
              dense
              outlined
            ></v-text-field>
          </div>
          <div class="mb-6">
            <v-btn
              @click="handleClickNew"
              block
              small
              color="primary"
              depressed
            >
              Nuevo
            </v-btn>
          </div>
        </div>

        <FormSection
          v-show="display === 0"
          ref="formSectionRef"
          :searchInput="searchInput || ''"
        />

        <FormModule
          v-show="display === 1"
          ref="formModuleSectionRef"
          :searchInput="searchInput || ''"
          :loadingFormModules="loadingFormModules"
        />

        <TemplateSection
          v-show="display === 2"
          ref="templateSectionRef"
          :searchInput="searchInput || ''"
          :loadingTemplates="loadingTemplates"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FormSection from "./Forms/FormSection.vue";
import TemplateSection from "./Forms/TemplateSection.vue";
import FormModule from "./Forms/FormModule.vue";
import { mapActions } from "vuex";

export default {
  name: "Forms",
  components: {
    FormSection,
    TemplateSection,
    FormModule,
  },
  data() {
    return {
      display: 0,
      searchInput: "",
      loadingTemplates: false,
      loadingFormModules: false,
    };
  },
  methods: {
    ...mapActions(["fetchTemplates", "fetchFormModule"]),
    handleClickNew() {
      if (this.display === 0) {
        this.$refs.formSectionRef.setToCreate();
      }

      if (this.display === 1) {
        this.$refs.formModuleSectionRef.setToCreate();
      }

      if (this.display === 2) {
        this.$refs.templateSectionRef.setToCreate();
      }
    },
    async getTemplates() {
      this.loadingTemplates = true;
      await this.fetchTemplates();
      this.loadingTemplates = false;
    },
    async getFormModules() {
      this.loadingFormModules = true;
      await this.fetchFormModule();
      this.loadingFormModules = false;
    },
  },
  watch: {
    display: function () {
      this.searchInput = "";
    },
  },
  created() {
    this.getTemplates();
    this.getFormModules();
  },
};
</script>
