<template>
  <div>
    <v-row>
      <v-col cols="12" v-if="!formsFiltered.length">
        <v-card flat class="pa-8 text-center d-flex justify-center">
          <v-progress-circular
            v-if="loading"
            indeterminate
            color="primary"
          ></v-progress-circular>
          <div v-else class="text-center grey--text">
            <v-icon color="grey" large>mdi-information</v-icon>
            <div class="mt-5">
              {{ noDataText }}
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="6"
        v-for="(item, index) in formsFiltered"
        :key="index"
      >
        <v-card
          class="pa-5"
          elevation="10"
          height="100%"
          style="position: relative"
        >
          <p class="subtitle-1 font-weight-medium mb-5">
            <span>{{ item.description }}</span>
          </p>
          <div
            v-for="(detail, index2) in item.formDetails
              .slice()
              .sort((a, b) => a.position - b.position)"
            :key="index2"
          >
            <v-divider class="my-3"></v-divider>
            <p class="subtitle-2 font-weight-regular my-0">
              {{ detail.position }}- {{ detail.description }}
            </p>
          </div>
          <div class="mt-8"></div>
          <div style="position: absolute; bottom: 15px; right: 15px">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  small
                  icon
                  class="mx-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="setToEdit(item)"
                >
                  <v-icon small>mdi-square-edit-outline</v-icon>
                </v-btn>
              </template>
              <span>Editar</span>
            </v-tooltip>
            <v-tooltip bottom small>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="showWarning(item)"
                  class="mx-1"
                  v-bind="attrs"
                  v-on="on"
                  text
                  small
                  icon
                >
                  <v-icon small> mdi-delete </v-icon>
                </v-btn>
              </template>
              <span>Eliminar</span>
            </v-tooltip>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "HistoryFormList",
  props: ["headers", "loading", "searchInput"],
  data() {
    return {
      defaultHeaders: [
        { text: "Descripción", value: "description", align: "center" },
        { text: "", value: "data-table-expand" },
      ],
    };
  },
  computed: {
    ...mapGetters(["forms"]),
    formsFiltered() {
      return this.forms.filter((e) => {
        // return all forms when searchInput is empty
        if (this.searchInput === "") return e;
        else {
          return e?.description
            ?.toLowerCase()
            ?.includes(this.searchInput.toLowerCase());
        }
      });
    },
    noDataText() {
      return this.searchInput !== ""
        ? "Formulario no encontrado"
        : "No hay formularios registrados...";
    },
  },
  methods: {
    setToEdit(item) {
      this.$emit("setToEdit", item);
    },
    showWarning(item) {
      this.$emit("showWarning", item);
    },
    formDetailSorted(formDetails) {
      return formDetails.sort((a, b) => a.position - b.position);
    },
  },
  created() {
    this.$emit("created");
  },
};
</script>
