<template>
  <div>
    <v-dialog v-model="showTemplate" width="1200" persistent @keydown.esc="showTemplate = false">
      <v-card class="pa-sm-10 pa-5">
        <div class="text-right">
          <v-icon @click="showTemplate = false" style="cursor: pointer">
            mdi-close
          </v-icon>
        </div>
        <TemplateForm
          :action="action"
          :item="template"
          @item-created="handleCreate"
          @item-updated="handleUpdate"
        />
      </v-card>
    </v-dialog>

    <WarningDialog
      ref="WarningDialogRef"
      :loading="deleting"
      @action="handleDelete"
    />

    <v-row class="mt-5">
      <v-col>
        <TemplateList
          @setToEdit="handleSetToEdit"
          @showWarning="showWarning"
          :loading="loadingTemplates"
          :searchInput="searchInput"
          :templates="list"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import WarningDialog from "../../components/WarningDialog.vue";
import TemplateForm from "../../components/Template/TemplateForm.vue";
import TemplateList from "../../components/Template/TemplateList.vue";
import { mapGetters, mapMutations } from "vuex";
import axios from "axios";

export default {
  name: "TemplateSection",
  components: {
    TemplateForm,
    TemplateList,
    WarningDialog,
  },
  props: ["searchInput", "templateList", "loadingTemplates"],
  data() {
    return {
      loading: false,
      showTemplate: false,
      action: "create",
      template: "",
      deleting: false,
    };
  },
  computed: {
    ...mapGetters(["templates"]),
    list() {
      return this.templateList ? this.templateList : this.templates;
    },
  },
  methods: {
    ...mapMutations([
      "pushTemplate",
      "updateTemplate",
      "removeTemplate",
      "setWarning",
      "setAlert",
    ]),
    handleCreate(template) {
      this.pushTemplate(template);
      this.showTemplate = false;
    },
    handleUpdate(template) {
      this.updateTemplate(template);
      this.showTemplate = false;
    },
    handleSetToEdit(template) {
      this.action = "update";
      this.template = template;
      this.showTemplate = true;
    },
    setToCreate() {
      this.template = { description: "", content: "" };
      this.action = "create";
      this.showTemplate = true;
    },
    showWarning(template) {
      this.template = template;
      this.$refs.WarningDialogRef.description = `¿Seguro que desea eliminar "${template.description}"?`;
      this.$refs.WarningDialogRef.show = true;
    },
    async handleDelete() {
      try {
        this.deleting = true;
        const res = await axios.delete(`/api/template/${this.template.id}`);
        this.warning = false;
        const { message } = res.data;
        this.setAlert({
          show: true,
          color: "success",
          icon: "mdi-check-circle",
          timeout: 4000,
          text: message,
        });
        this.removeTemplate(this.template);
      } catch (error) {
        console.log(error);
        this.setAlert({
          show: true,
          color: "error",
          icon: "mdi-alert-remove",
          text: error.response.data.message,
        });
      } finally {
        this.deleting = false;
        this.$refs.WarningDialogRef.show = false;
      }
    },
  },
};
</script>
