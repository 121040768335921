<template>
  <div>
    <v-form
      ref="form"
      @submit.prevent="
        action === 'create' ? createFormHistory() : editFormHistory()
      "
    >
      <v-container>
        <Title
          :title="
            action === 'create' ? 'Crear formulario' : 'Actualizar formulario'
          "
          class="mb-8"
        />
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="description"
              type="text"
              label="Descripción *"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4">
            <p class="mb-5">Seleccionar módulos</p>
            <div v-for="(sub, index) in modules" :key="index" class="my-5">
              <v-checkbox
                v-model="sub.added"
                @click="!sub.added ? removeFromList(sub) : addToList(sub)"
                :label="sub.description"
                color="primary"
                hide-details
              ></v-checkbox>
            </div>
          </v-col>
          <v-col cols="12" md="8">
            <p class="text-center">Módulos *</p>
            <v-divider></v-divider>
            <v-data-table
              :items="formDetails"
              :items-per-page="formDetails.length"
              item-key="subForm"
              no-data-text="No hay módulos agregados..."
              hide-default-footer
              item-class="text-center"
              must-sort
            >
              <template v-slot:body>
                <draggable :list="formDetails" tag="tbody">
                  <tr
                    v-for="(turn, index) in formDetails"
                    :key="index"
                    style="cursor: move"
                  >
                    <td class="text-center">
                      {{ index + 1 }}
                    </td>
                    <td>
                      {{ turn.description }}
                    </td>
                    <td class="text-center">
                      <v-icon small @click="removeFromList(turn)">
                        mdi-close
                      </v-icon>
                    </td>
                  </tr>
                </draggable>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              small
              color="primary"
              class="pa-5"
              type="submit"
              depressed
              :disabled="!validation"
              :loading="loading"
            >
              Guardar
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>

<script>
import axios from "axios";
import { mapMutations } from "vuex";
import draggable from "vuedraggable";
import Title from "../Title.vue";

export default {
  name: "HistoryForm",
  props: ["action", "formValues", "modulesToSelected"],
  components: {
    draggable,
    Title,
  },
  data() {
    return {
      id: "",
      description: "",
      formDetails: [],
      loading: false,
      modules: [],
    };
  },
  computed: {
    validation() {
      return this.description !== "" && this.formDetails.length;
    },
  },
  methods: {
    ...mapMutations(["setAlert"]),
    removeFromList(item) {
      // remove from item selected list
      this.formDetails = this.formDetails.filter((i) => i.code !== item.code);

      // uncheck the item
      this.modules = this.modules.map((i) => {
        if (i.code === item.code) {
          i.added = false;
        }
        return i;
      });
    },
    addToList(item) {
      this.formDetails.push(item);
    },
    async createFormHistory() {
      try {
        this.loading = true;
        const FormHistoryToCreate = {
          description: this.description,
          formDetails: this.formDetails.map((item) => {
            return {
              ...item,
              code: item.code,
            };
          }),
        };

        const res = await axios.post("/api/form", FormHistoryToCreate);
        const { form, message } = res.data;
        this.setAlert({
          show: true,
          color: "success",
          icon: "mdi-check-circle",
          timeout: 4000,
          text: message,
        });
        this.$emit("formHistory-created", form);
      } catch (error) {
        console.log(error);
        this.setAlert({
          show: true,
          color: "error",
          icon: "mdi-alert-remove",
          text: error.response.data.message,
        });
      } finally {
        this.loading = false;
      }
    },
    async editFormHistory() {
      try {
        this.loading = true;
        const FormHistoryToUpdate = {
          id: this.id,
          description: this.description,
          formDetails: this.formDetails.map((item) => {
            return {
              ...item,
              code: item.code,
            };
          }),
        };
        const res = await axios.put(
          `/api/form/${this.id}`,
          FormHistoryToUpdate
        );
        const { form, message } = res.data;
        this.setAlert({
          show: true,
          color: "success",
          icon: "mdi-check-circle",
          timeout: 4000,
          text: message,
        });
        this.$emit("formHistory-updated", form);
      } catch (error) {
        console.log(error);
        this.setAlert({
          show: true,
          color: "error",
          icon: "mdi-alert-remove",
          text: error.response.data.message,
        });
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    formValues: {
      immediate: true,
      handler() {
        this.modules = this.modulesToSelected;

        this.modules = this.modules.map((item) => {
          item.added = false;
          return item;
        });

        this.id = this.formValues.id;
        this.description = this.formValues.description;
        this.formDetails = this.formValues.formDetails.sort(
          (a, b) => a.position - b.position
        );

        this.modules = this.modules.map((item) => {
          if (this.formDetails.some((e) => e.code === item.code)) {
            item.added = true;
          }
          return item;
        });
      },
    },
  },
  created() {
    this.modules = this.modulesToSelected;
  },
};
</script>
